var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "more_takeout" },
    [
      _c("i-header", {
        attrs: {
          title: "选择外卖商家",
          showBack: !_vm.$route.query.fromWx || this.isWx(),
        },
        on: { "back-event": _vm.goBack },
      }),
      _c(
        "div",
        { staticClass: "msg" },
        [
          _c("i-icon", {
            attrs: { color: "var(--themeColor)", name: "icon-gonggao" },
          }),
          _c("span", [_vm._v(" 仅支持查询外卖商家，无法提供预订服务 ")]),
        ],
        1
      ),
      _vm._l(_vm.itemList, function (item, index) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: item.show,
                expression: "item.show",
              },
            ],
            key: index,
            staticClass: "takeout_item",
          },
          [
            _c(
              "div",
              { staticClass: "item_left" },
              [
                _c("i-icon", { attrs: { name: item.icon } }),
                _vm._v(" " + _vm._s(item.txt) + " "),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "item_right",
                on: {
                  click: function ($event) {
                    return _vm.handlechannel(item)
                  },
                },
              },
              [_vm._v(" 去看看 ")]
            ),
          ]
        )
      }),
      _vm.goWxShow
        ? _c("div", { staticClass: "WXShadow" }, [
            _c("img", { attrs: { src: require("../assets/img/jiantou.png") } }),
            _c("div", { staticClass: "shadowtext" }, [
              _c("div", [_vm._v("温馨提示")]),
              _c("span", [_vm._v("请在外部浏览器中打开")]),
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      _vm.goWxShow = false
                    },
                  },
                },
                [_vm._v("我知道了")]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }