<template>
    <div class="more_takeout">
        <i-header title="选择外卖商家" :showBack="!$route.query.fromWx || this.isWx()" @back-event="goBack"></i-header>
        <div class="msg">
            <i-icon color="var(--themeColor)" name="icon-gonggao" />
            <span>
                仅支持查询外卖商家，无法提供预订服务
            </span>
        </div>
        <div 
            class="takeout_item" 
            v-for="(item, index) in itemList"
            v-show="item.show"
            :key="index">
            <div class="item_left">
                <i-icon :name="item.icon" />
                {{ item.txt }}
            </div>
            <div class="item_right" @click="handlechannel(item)">
                去看看
            </div>
        </div>
        <div class="WXShadow" v-if="goWxShow">
            <img src="../assets/img/jiantou.png" />
            <div class="shadowtext">
                <div>温馨提示</div>
                <span>请在外部浏览器中打开</span>
                <a @click="goWxShow = false">我知道了</a>
            </div>
        </div>
    </div>
</template>

<script>

import * as tinyCookie from "tiny-cookie";
export default {
    data() {
        return {
            itemList:[],
            mealRules: {},
            goWxShow: false
        };
    },
    async created(){
        let res = await this.getMealRules()
        if(this.$route.query.fromWx && !this.isWx()){
            this.itemList = res.takeOutList.map(item => {
                    if(item.itemType == 'ELE'){
                        return item
                    }else{
                        return false
                    }
                }
            )
        }else{
            this.itemList = res.takeOutList
        }
        this.mealRules = res
    },
    methods: {
		generateUUID() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0,
					v = c == 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
		},
        isWx() {
            var ua = navigator.userAgent.toLowerCase();
            if (/MicroMessenger/gi.test(ua)) {
                return true;
            } else {
                return false;
            }
        },
        async handlechannel(item){
            switch (item.itemType) {
                case 'ELE':
                    if( this.isWx()){
                        this.goWxShow = true
                        this.$router.push({
                            path: '/moreTakeOut',
                            query: {
                                ...this.$route.query,
                                fromWx: true,
                                sessionId: this.$smartStorage.get("sessionId")
                            },
                        })
                        return
                    }
                    var params = {
                        "ItemId": this.generateUUID(),
                        "Amount": 0.01,
                        "Sid": this.mealRules.sid,
                        "ItemType": 7
                    }
                    var elemeURL = await this.$service.common('/rpc/pay/Alipay/GenerateReadOnlyAliTakeoutUrl', params)
                    if(elemeURL && elemeURL.success){
                        window.location.href = elemeURL.content
                    }
                break;
                case "xiaomishu":
                    var appData = {
                        paramCode: {
                            city: this.fieldValue || '',
                            search: true,
                        },
                        isFromMore: true,
                        tenant_code: tinyCookie.getCookie("tenant_code"),
                        token: tinyCookie.getCookie("token"),
                    };
                    var appConf = {
                        webAppAlias: this.$route.query.webAppAlias,
                        path: this.$route.query.path,
                        params: `?appType=iMeal&rnd=${new Date().getTime()}&merchantsType=1&mcOnly=1&isQuery=true&action=order&appType=iMeal&noBack=false`
                    };
                    this.$publicFun.openWebAppCate(appData, appConf);
                    break
                default:
                    break;
            }
        },
        goBack(){
            this.$router.push({path: '/application'})
        },
        async getMealRules(){
            let params = {
                collection: "cfg-imeal-mealrules",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenant_id: this.$smartStorage.get("tenant_id") || this.$cookie.get("tenant_id"),
                },
            };
            return new Promise((resolve, reject) => {   
                this.$service.QuerySettings(params).then(res => {
                    if (res && res.success && res.content) {
                        console.log(res.content[0]);
                        resolve(res.content[0])
                    }else{
                        resolve({})
                    }
                }).catch(err => {
                    reject(false)
                })
            })
        },
    },
};
</script>

<style scoped lang="less">
.more_takeout{
    .msg{
        margin: 0 auto;
        width: 95%;
        line-height: .35rem;
        color: var(--themeColor);
        background: var(--opacityColor);
        border-radius: .05rem;
        display: flex;
        align-items: center;
        svg{
            margin-left: .15rem;
        }
        span{
            margin-left: .1rem;
        }
    }
    .takeout_item{
        width: calc(95% - .2rem);
        margin: 0 auto;
        padding: .1rem;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        margin-top: .1rem;
        display: flex;
        justify-content: space-between;
            align-items: center;
        .item_left{
            display: flex;
            align-items: center;
            font-size: .17rem;
            svg{
                width: .45rem!important;
                height: .45rem!important;
                margin-right: .15rem;
            }
        }
        .item_right{
            width: .65rem;
            line-height: .3rem;
            text-align: center;
            background: var(--opacityColor);
            color: var(--themeColor);
            border-radius: .05rem;
        }
    }
        .WXShadow {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            position: fixed;
            top: 40px;
            z-index: 100;
            img {
                width: 36%;
                height: 25%;
                float: right;
                margin-right: 0.2rem;
                margin-top: 0.2rem;
            }
            .shadowtext {
                font-size: 0.2rem;
                position: fixed;
                top: 2rem;
                width: 80%;
                background: #fff;
                left: 5%;
                text-align: center;
                padding: 0.15rem;
                padding-bottom: 0.25rem;
                border-radius: 0.1rem;
                box-shadow: 0 0 9px 0px #2a292966;
            div {
                font-weight: 600;
                margin-bottom: 0.2rem;
            }
            span {
                font-size: 0.16rem;
            }
            a {
                display: block;
                width: 60%;
                font-size: 0.16rem;
                background: var(--themeColor);
                color: #fff;
                padding: 0.12rem 0.48rem;
                border-radius: 0.05rem;
                margin: 0 auto;
                margin-top: 0.5rem;
            }
            }
        }
}
</style>
